<template>
  <div class="feedback-page" :style="{ minHeight: clientHeight + 'px' }">
    <div class="feedback-row">
      <ul class="feedback-list">
        <li v-for="(item, index) in productList"
          :key="index"
          :class="{ isChecked: item.isChecked }"
          @click="productAction(item)">
          <span>{{ item.name }}</span>
        </li>
      </ul>
      <div class="feedback-textarea">
        <textarea placeholder="请填写反馈内容，越详细越好哦" v-model="content"
          spellcheck="false"></textarea>
      </div>
      <div class="feedback-user">
        <input type="text" placeholder="手机号/QQ号/微信号/邮箱" v-model="ccontact">
      </div>
      <div class="submit">
        <div class="button" @click="submitAction">提交意见</div>
      </div>
      <div class="feedback-qq" @click="jumpQQAction" v-if="false">
        <p class="desc">快速反馈</p>
        <div class="qq-button">QQ群</div>
        <div class="icon">
          <img src="data:image/png;base64,
          iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAbFBMVEUAAADX19fY
          2NjY2NjZ2dnY2NjY2NjY2NjY2NjZ2dnZ2dnY2NjY2NjY2NjZ2dna2trY2NjZ2dnY
          2NjY2NjX19fY2NjX19fY2NjY2NjY2NjX19fZ2dnZ2dnX19fW1tbc3Nzb29vb29vV
          1dXY2NjX+/wjAAAAI3RSTlMAgOFoVvLMwbuqiXt2cm1iXEj769vTs5ePg2A8NS0l
          HRUOBjxQEg8AAAB4SURBVDjLzdLHDcAwDAPA9N57b95/xzALkN/o64MFULT+NbaJ
          HwqMMcNNf4CILiZSiPBkIoMIDiZyiH5nooDoNiZcCGdVoq2ZKCF8KiqIxmPCg3AU
          CMUKn7wvYkH5xS1iiETUsTjWKM49icIkonKJKO0sam9b/5wXTlYMRaQRnssAAAAA
          SUVORK5CYII=" alt="">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getAppStatus, callAppHandler } from '@/utils/app';
import { productProposalApi } from '@/api/base';
import { isString } from '@/utils';

export default {
  name: 'ThreejzFeedback',
  data() {
    return {
      productList: [
        { name: '产品建议', id: 0, isChecked: true },
        { name: '使用故障', id: 1 },
        { name: '我要吐槽', id: 2 },
      ],
      clientHeight: 0,
      content: '',
      ccontact: '',
      cuserId: getAppStatus().cuserId,
    };
  },
  computed: {
    cuserIds() {
      // return '3129b4b2-a736-4e9b-97d8-7a8ee7f357fe';
      return getAppStatus().cuserId;
    },
    appInfos() {
      let data = '{}';
      if (/android/i.test(navigator.userAgent)) {
        data = window?.android?.jzAppInfo();
      }
      return data;
    },
    isIos() {
      return /i(os|phone|pad)/i.test(navigator.userAgent);
    },
    isAndroid() {
      return /android/i.test(navigator.userAgent);
    },
  },
  created() {
    document.title = '意见反馈';
  },
  mounted() {
    // this.productProposal();
    this.clientHeight = document.body.clientHeight;
    if (this.isAndroid) {
      this.appInfo = window?.android?.jzAppInfo();
    }
    if (!this.cuserId && this.isIos) {
      let userId = sessionStorage.getItem('userId');
      let appInfo = sessionStorage.getItem('appInfo');
      if (userId) {
        this.userId = userId;
      }
      if (appInfo) {
        this.appInfo = appInfo;
      }
      callAppHandler('jzUserId', null, (res) => {
        console.log(res);
        this.cuserId = res;
        sessionStorage.setItem('userId', res);
      });
      callAppHandler('jzAppInfo', null, (res) => {
        console.log(res, 'appInfo');
        sessionStorage.setItem('appInfo', JSON.stringify(res));
        this.appInfo = res;
      });
    }
  },
  methods: {
    productAction(data) {
      const { productList } = this;
      this.productList.forEach((item) => {
        this.$set(item, 'isChecked', false);
      });
      this.$set(data, 'isChecked', true);
    },
    submitAction() {
      let {
        content, ccontact, productList, appInfo,
      } = this;
      if (isString(appInfo)) {
        appInfo = JSON.parse(appInfo);
      }
      if (!content) {
        return this.$toast({ content: '请填写反馈内容哦！' });
      }
      if (!ccontact) {
        return this.$toast({ content: '请填写手机微信联系方式哦！' });
      }
      const checkedData = productList.find((item) => item.isChecked === true);
      let ilabel = checkedData.id;
      const data = {
        cuserid: this.cuserId || '',
        ccontent: content,
        ccontact,
        ilabel,
        type:	1, // 建议type=1  固定值
        system: 0, // 固定值
        cmodel:	appInfo?.device || '', // 手机型号
        cversion: appInfo?.appVersion || '', // 版本号
        cphoneos:	appInfo?.cphoneos || '', // 手机系统
        iedition: 2,
      };
      this.productProposal(data, appInfo);
      // console.log(data);
    },
    async productProposal(params, appInfo) {
      let res;
      let name;
      if (appInfo) {
        name = encodeURIComponent(appInfo.name);
      }
      try {
        res = await productProposalApi(params, { name });
      } catch (e) {
        console.log(e);
      }
      if (res.code === 1) {
        this.$toast({ content: '提交成功，感谢您的反馈！' });
        this.$router.go(-1);
      }
    },
    jumpQQAction() {
      if (/android/i.test(navigator.userAgent)) {
        window.android.joinQQGroup('Z0E2MJdJ8QSW2mt4-05BUFVFPD_jRvwZ');
      }
      if (/i(os|phone|pad)/i.test(navigator.userAgent)) {
        window.ios?.joinQQGroup('796996244,f9f51feb69b90fca819b00e8f97813e348d43a2dab041c268099cb30e628f045');
      }
    },
  },
  beforeDestroy() {
    delete window.WVJBCallbacks;
  },
};
</script>
<style lang="scss" scoped>
  .feedback-page {
    width: 100%;
    height: 100%;
    background-color: #f6f6f6;
    overflow: hidden;
  }
  .feedback-row {
   margin-top: 30px;
  //  background-color: #fff;
  }
  .feedback-list {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    // margin: 0 26px;
    padding: 27px 26px;
    border-bottom: 1PX solid #EAE7E7;
    li {
      width: 200px;
      height: 76px;
      border-radius: 40px;
      border: 3px solid #FFDC4D;
      font-weight: 400;
      color: #333333;
      font-size: 30px;
      text-align: center;
      line-height: 76px;
      &.isChecked {
        background: #FFDC4D;
      }
    }
  }
  textarea {
    font-size: 28px;
    color: #999999;
    line-height: 40px;
    border: none;
    width: 100%;
    min-height: 230px;
    resize: none;
    padding-left: 15px;
    padding-top: 40px;
    &:-ms-input-placeholder {
      font-size: 28px;
      color: #999999;
    }
    &::-webkit-input-placeholder {
      font-size: 28px;
      color: #999999;
    }
  }
  input {
    padding-left: 25px;
    border: none;
    font-size: 28px;
    color: #999999;
    line-height: 40px;
    padding-top: 31px;
    padding-bottom: 27px;
    width: 100%;
    &:-ms-input-placeholder {
      font-size: 28px;
      color: #999999;
    }
    &::-webkit-input-placeholder {
      font-size: 28px;
      color: #999999;
    }
  }
  .feedback-user {
    background-color: #fff;
    margin-top: 15px;
  }
  .submit {
    padding: 26px 0 24px 0;
    background-color: #fff;
    margin-top: 20px;
    .button {
      margin: 0 27px;
      height: 88px;
      background: #FFDC4D;
      border-radius: 44px;
      text-align: center;
      line-height: 88px;
      font-weight: 500;
      color: #333333;
      font-size: 32px;
    }
  }
  .feedback-qq {
    margin-top: 57px;
    position: relative;
    .desc {
      padding-left: 28px;
      font-weight: 400;
      color: #999999;
      line-height: 33px;
      font-size: 24px;
    }
    .qq-button {
      font-size: 30px;
      font-weight: 400;
      color: #333333;
      padding-left: 25px;
      padding-top: 28px;
      padding-bottom: 30px;
      background: #fff;
      margin-top: 16px;
    }
    .icon {
      position: absolute;
      top: 80px;
      // transform: translateY(-50%);
      right: 25px;
      img {
        width: 30px;
      }
    }
  }
</style>
